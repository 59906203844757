import React, { forwardRef } from "react";
import videojs from "video.js";
import { concatClassNames as cn } from "@sys42/utils";
import "video.js/dist/video-js.css";
import "./themeFantasy.css";
import * as styles from "./styles.module.css";

// https://docs.videojs.com/tutorial-react.html

export const VideoJS = forwardRef((props, ref) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const { options, onReady, ...containerProps } = props;

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;
      const mergedOptions = {
        controls: true,
        autoplay: false,
        preload: "auto",
        fluid: true,
        aspectRatio: "16:9",
        controlBar: {
          seekToLive: false,
          pictureInPictureToggle: false,
          volumePanel: {
            inline: true,
          },
        },
        ...options,
      };
      playerRef.current = videojs(videoElement, mergedOptions, () => {
        onReady && onReady(playerRef.current);
      });
    } else {
      // you can update player here [update player through props]
      playerRef.current.src(options.sources);
    }
  }, [options, onReady]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <div {...containerProps} ref={ref}>
      {
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video
          ref={videoRef}
          className={cn("video-js", "vjs-theme-fantasy", styles.vjsUserbrain)}
          playsInline
        />
      }
    </div>
  );
});

export default VideoJS;
