import React from "react";
import { accessibleOnClick, concatClassNames as cn } from "@sys42/utils";
// import { } from '../../system42'
import * as styles from "./taskStep.module.css";

import taskIcon from "./taskIcon.svg";

export function TaskStep(props) {
  const { className, task, response, title, onClickTitle, ...containerProps } =
    props;

  switch (task.type) {
    case "rating_scale":
      const scaleItems = [];

      for (let i = task.options.scale_start; i <= task.options.scale_end; i++) {
        scaleItems.push(i);
      }

      return (
        <div className={cn(className, styles.taskStep)} {...containerProps}>
          <div className={styles.header} {...accessibleOnClick(onClickTitle)}>
            <img src={taskIcon} alt={taskIcon} />
            <h3>{title}</h3>
          </div>
          <div className={styles.content}>
            {task.task}
            <div className={styles.answer}>
              <div className={styles.ratingLabels}>
                <div className={styles.ratingLabel}>
                  {task.options.scale_start_label}
                </div>
                <div
                  className={cn(styles.ratingLabel, styles.ratingLabelRight)}
                >
                  {task.options.scale_end_label}
                </div>
              </div>
              <div className={styles.ratingItems}>
                {scaleItems.map((item, index) => (
                  <div
                    key={index}
                    className={cn(
                      styles.ratingItem,
                      response.response?.rating === item &&
                      styles.ratingItem_active
                    )}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    case "single_choice":
      return (
        <div className={cn(className, styles.taskStep)} {...containerProps}>
          <div className={styles.header} {...accessibleOnClick(onClickTitle)}>
            <img src={taskIcon} alt={taskIcon} />
            <h3>{title}</h3>
          </div>
          <div className={styles.content}>
            {task.task}
            <div className={styles.answer}>
              <div>
                <strong>Answer</strong>
              </div>
              {task.options.answers.map((choice, index) => (
                <div key={index}>
                  {response.response?.choice.indexOf(index) === -1 ? (
                    <label>
                      <input type="radio" disabled /> {choice}
                    </label>
                  ) : (
                    <label>
                      <input type="radio" disabled checked /> {choice}
                    </label>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    case "multiple_choice":
      return (
        <div className={cn(className, styles.taskStep)} {...containerProps}>
          <div className={styles.header} {...accessibleOnClick(onClickTitle)}>
            <img src={taskIcon} alt={taskIcon} />
            <h3>{title}</h3>
          </div>
          <div className={styles.content}>
            {task.task}
            <div className={styles.answer}>
              <div>
                <strong>Answer</strong>
              </div>
              {task.options.answers.map((choice, index) => (
                <div key={index}>
                  {response.response?.choice.indexOf(index) === -1 ? (
                    <label>
                      <input type="checkbox" disabled /> {choice}
                    </label>
                  ) : (
                    <label>
                      <input type="checkbox" disabled checked /> {choice}
                    </label>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    case "written_response":
      return (
        <div className={cn(className, styles.taskStep)} {...containerProps}>
          <div className={styles.header} {...accessibleOnClick(onClickTitle)}>
            <img src={taskIcon} alt={taskIcon} />
            <h3>{title}</h3>
          </div>
          <div className={styles.content}>
            {task.task}
            <div className={styles.answer}>
              <div>
                <strong>Answer</strong>
              </div>
              {response.response.response}
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div className={cn(className, styles.taskStep)} {...containerProps}>
          <div className={styles.header} {...accessibleOnClick(onClickTitle)}>
            <img src={taskIcon} alt={taskIcon} />
            <h3>{title}</h3>
          </div>
          <div className={styles.content}>{task.task}</div>
          {task.options?.ask_completed && (
            <div className={styles.answer}>
              <strong>
                {response.response.completed ? "Task completed" : "Task failed"}
              </strong>
            </div>
          )}
        </div>
      );
  }
}
