import React from "react";
import { concatClassNames as cn } from "@sys42/utils";
import { Link } from "gatsby-plugin-intl";
// import { } from '../../system42'
import * as styles from "./styles.module.css";

export function ExampleTags(props) {
  const { className, example, ...containerProps } = props;

  return (
    <div className={cn(className, styles.exampleTags)} {...containerProps}>
      <Link
        className={styles.tag}
        to={`/user-testing-template-library/types/${example.type.toLowerCase() === "webapp" ? "web-app" : example.type
          }`}
      >
        {example.type}
      </Link>
      <Link
        className={cn(styles.tag, styles.tag_device)}
        to={`/user-testing-template-library/devices/${example.device}`}
      >
        {example.device}
      </Link>
      {example.categories.map((category, categoryIndex) => (
        <Link
          key={categoryIndex}
          className={styles.tag}
          to={`/user-testing-template-library/categories/${category.url}`}
        >
          {category.title}
        </Link>
      ))}
      {example.taskTypes.map((taskType, taskTypeIndex) => (
        <Link
          key={taskTypeIndex}
          className={cn(styles.tag, styles.tag_task)}
          to={`/user-testing-template-library/task-types/${taskType.url}`}
        >
          {taskType.title}
        </Link>
      ))}
    </div>
  );
}
