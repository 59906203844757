import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { graphql } from "gatsby";
import { useLocation } from "@reach/router";
import Layout from "../../layouts/layout";
import {
  addUtmParams,
  ButtonPrimary,
  ButtonPrimaryLarge,
  Headline3Sans,
  imgLogoFull,
  Overline,
  urlCustomerLogin,
} from "@userbrain/website-ui";
import * as styles from "./styles.module.css";
import * as headerStyles from "./header.module.css";
import { Link, navigate } from "gatsby-plugin-intl";
import { getCreateAccountUrl } from "../../helpers";
import { ExampleTags } from "../../components/ExampleTags";
import { TaskStep } from "./TaskStep.js";
import VideoJs from "../../components/VideoJs";
import { useInViewport } from "react-in-viewport";

const startFreeTrialUrlExample = addUtmParams(
  getCreateAccountUrl(),
  "website",
  "example"
);

export default function Example(props) {
  const { data } = props;

  const example = data.strapiExample;

  const userbrainExample = data.userbrainExample;

  const refVideoContainer = useRef();
  const refVideoJs = useRef();
  const refVideoJsPlayer = useRef();

  // .../index?context=category_portfolio
  const location = useLocation();
  const contextParam = new URLSearchParams(location.search).get("context");
  const contextSplit = contextParam?.split("_") || [];
  let context = null;
  let backTo = null;
  let backName = null;
  if (contextSplit[0] === "category") {
    context = "category";
    backTo = `/user-testing-templates/`;
    backName = "Categories";
    if (contextSplit.length === 2) {
      backTo = `/user-testing-templates/categories/${contextSplit[1]}/`;
      backName = contextSplit[1];
    }
  } else if (contextSplit[0] === "library") {
    context = "library";
    backTo = `/user-testing-template-library/`;
    backName = "Library";
    if (contextSplit.length > 1) {
      for (let i = 1; i < contextSplit.length; i++) {
        backTo += `${contextSplit[i]}/`;
        // backName += ` / ${contextSplit[i]}`;
      }
    }
  }

  const { inViewport: videoInViewport } = useInViewport(
    refVideoJs,
    {},
    { disconnectOnLeave: false },
    {}
  );

  const handleReadyVideoJs = useCallback((player) => {
    refVideoJsPlayer.current = player;
  }, []);

  const videoJsOptions = useMemo(
    () => ({
      sources: [
        {
          type: "video/mp4",
          src: `https://space.userbrain.com/examples/${userbrainExample.shared_hash}.mp4`,
        },
      ],
    }),
    [userbrainExample.shared_hash]
  );

  // Today: No sun.

  function handleClickTaskStep(task, previousTask) {
    if (!videoInViewport && refVideoJs.current) {
      refVideoJs.current.scrollIntoView();
    }
    let timeStart = 0;
    if (previousTask) {
      const previousTaskResponse = userbrainExample.responses?.find(
        (response) => response?.teststep_id === previousTask.id
      );
      timeStart = previousTaskResponse?.timestamp_completed || -1;
    }
    const HAVE_CURRENT_DATA = 2;
    if (
      refVideoJsPlayer.current?.readyState() >= HAVE_CURRENT_DATA &&
      timeStart !== -1
    ) {
      refVideoJsPlayer.current.currentTime(timeStart);
      refVideoJsPlayer.current.play();
    }
  }

  useEffect(() => {
    function handleScroll() {
      if (refVideoContainer.current && refVideoJs.current) {
        const computedStyle = window.getComputedStyle(
          refVideoContainer.current,
          null
        );
        const maxOffset = Math.max(
          0,
          parseFloat(computedStyle.getPropertyValue("height")) -
            refVideoJs.current.offsetHeight
        );
        const videoOffsetY = Math.min(maxOffset, window.scrollY);
        refVideoJs.current.style.top = `${videoOffsetY}px`;
      }
    }

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleClickBack() {
    navigate(backTo);
  }

  function handleClickMoreTemplates() {
    navigate(`/user-testing-templates/`);
  }

  const exampleHeader = (
    <div className={headerStyles.header}>
      <h1 className={headerStyles.logoH1}>
        <Link to={"/"}>
          <img src={imgLogoFull} alt="Userbrain" />
        </Link>
      </h1>
      <div className={headerStyles.freeTrialAndLogin}>
        <a href={urlCustomerLogin} className={headerStyles.login}>
          Login
        </a>
        <ButtonPrimary
          customTag={"a"}
          href={startFreeTrialUrlExample}
          className={headerStyles.startFreeTrial}
        >
          Start free trial
        </ButtonPrimary>
      </div>
    </div>
  );

  return (
    <Layout customHeader={exampleHeader} title={example.companyName}>
      <div className={styles.contentAndVideoLayout}>
        <div ref={refVideoContainer} className={styles.videoContainer}>
          <VideoJs
            ref={refVideoJs}
            onReady={handleReadyVideoJs}
            className={styles.videoPlayer}
            options={videoJsOptions}
          />
        </div>
        <div className={styles.contentContainer}>
          <div className={styles.contentContainerContent}>
            <div className={styles.topNavigation}>
              {(context === "category" || context === "library") && (
                <button
                  className={styles.buttonAllExamples}
                  onClick={handleClickBack}
                >
                  {backName}&nbsp;/&nbsp;
                </button>
              )}
              {context === null && (
                <button
                  className={styles.buttonAllExamples}
                  onClick={handleClickMoreTemplates}
                >
                  More Templates&nbsp;/&nbsp;
                </button>
              )}
              <img
                className={styles.navCompanyLogo}
                src={example.logo.localFile.publicURL}
                alt={`Logo: ${example.companyName}`}
              />
              <span className={styles.navCompanyName}>
                {example.companyName}
              </span>
            </div>

            <Headline3Sans className={styles.title} customTag={"h2"}>
              {data.strapiExample.title}
            </Headline3Sans>

            <ExampleTags className={styles.tags} example={example} />

            <div className={styles.demograhicsAndUrl}>
              <div>
                <Overline customTag={"h3"}>Demographics</Overline>
                {userbrainExample.tester.country_of_residence}
              </div>
              <div>
                <Overline customTag={"h3"}>Website URL</Overline>
                <div className={styles.websiteUrl}>
                  {userbrainExample.test_url}
                </div>
              </div>
            </div>

            <div className={styles.task}>
              {userbrainExample.task?.map((task, index) => (
                <TaskStep
                  key={task.id}
                  task={task}
                  onClickTitle={() =>
                    handleClickTaskStep(task, userbrainExample.task[index - 1])
                  }
                  response={userbrainExample.responses.find(
                    (response) => response.teststep_id === task.id
                  )}
                  title={`Task ${index + 1}`}
                />
              ))}
            </div>

            {/*            <div className={styles.exampleFooter}>
              <ButtonPrimaryLarge>Use test as template</ButtonPrimaryLarge>
              <p>By clicking here, you can set up your very own user test with this template as a base. Your first ever
                user test is free, and you can adapt the template later.</p>
            </div>*/}

            <div className={styles.exampleFooter}>
              <ButtonPrimaryLarge
                customTag={"a"}
                href={`${process.env.GATSBY_USERBRAIN_DASHBOARD_URL}/create-test-from-template/${userbrainExample.shared_hash}`}
              >
                Use this template
              </ButtonPrimaryLarge>
              <p className={styles.useThisTemplateDescription}>
                By clicking here, you can set up your very own user test with
                this template as a base. Your first ever user test is free, and
                you can adapt the template later.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query Example($strapiId: String!, $sharedHash: String!) {
    strapiExample(id: { eq: $strapiId }) {
      id
      url
      title
      type
      device
      companyName
      cardTitleWhite
      taskTypes {
        title
        url
      }
      categories {
        title
        url
      }
      cardColor
      logo {
        localFile {
          publicURL
        }
      }
    }
    userbrainExample(shared_hash: { eq: $sharedHash }) {
      id
      title
      shared_hash
      test_url
      duration
      tester {
        country_of_residence
      }
      task {
        id
        task
        options {
          answers
          ask_completed
          scale_start
          scale_end
          scale_start_label
          scale_end_label
        }
        type
      }
      responses {
        teststep_id
        timestamp_completed
        response {
          choice
          completed
          response
          rating
        }
      }
    }
  }
`;
